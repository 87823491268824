import '../Boton_WA/whatsapp-footer.css';
import LogoWa from '../../../img/logo-wa.png';

function waLink() { window.open('https://wa.link/auu19q', '_blank') }

function WhatsappFooter() {
    return(

        <button onClick={waLink}>

            <img src={LogoWa} alt="" />

            <div className="black-layer"></div>

        </button>

    );
}

export default WhatsappFooter;